import React from "react";
import moment from "moment";
import "moment/locale/it";
import up from "../../../img/arrow_up_dark.png";
import down from "../../../img/arrow_down_dark.png";

moment.locale("it");

function defineOrder(prevOrder, field) {
  let internalOrder = "";
  if (prevOrder.includes(":DESC")) {
    internalOrder = ":ASC";
  } else {
    internalOrder = ":DESC";
  }
  return field.value + internalOrder;
}

const Table = ({ list, fields, colors, setOpenRecord, order, setOrder }) => {
  const styles = {
    table: {
      textAlign: "center",
      borderCollapse: "collapse",
      border: "1px solid #ddd",
      width: "100%",
      overflow: "scrollY",
    },
    headerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textAlign: "center",
      backgroundColor: colors.verylightgray,
      fontSize: 12,
      color: colors.gray,
    },
    field: {
      border: "1px solid #ddd",
      padding: "8px",
      fontSize: 12,
    },
    row: {
      cursor: "pointer",
    },
  };

  const renderTableHeader = () => {
    return (
      <tr>
        {fields?.map((field, index) => {
          return (
            <th
              style={styles.headerCell}
              key={index}
              onClick={() =>
                field.sortable ? setOrder(defineOrder(order, field)) : null
              }
            >
              {field.label.toUpperCase() + "    "}
              {order.includes(field.value) && (
                <img
                  src={order.includes("DESC") ? down : up}
                  height={5}
                  alt={"Order button"}
                />
              )}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    return list?.map((el, key) => {
      return (
        <tr key={key} style={styles.row} onClick={() => setOpenRecord(el)}>
          {fields.map((field, index) => {
            return (
              <td key={index} style={styles.field}>
                {!!field.subvalue
                  ? el[field.value][field.subvalue]
                  : el[field.value]}
              </td>
            );
          })}
        </tr>
      );
    });
  };
  return (
    <table style={styles.table}>
      <thead>{renderTableHeader()}</thead>
      <tbody>{renderTableData()}</tbody>
    </table>
  );
};

export default Table;
