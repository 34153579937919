export const accessFields = [
  { label: "Data", value: "timestamp", sortable: true },
  { label: "Utente", value: "user", subvalue: "username", sortable: true },
  { label: "Azione", value: "action", sortable: true },
  {
    label: "Sede",
    value: "operationCentre",
    subvalue: "name",
    sortable: true,
  },
  {
    label: "Punto di accesso",
    value: "accessPoint",
    subvalue: "name",
    sortable: true,
  },
  { label: "Tipologia", value: "type", sortable: true },
];

export const accessFilterFields = [
  {
    identifier: "date",
    label: "Data",
    var: "timestamp",
  },
  {
    identifier: "user",
    label: "Dipendente",
    var: "user",
  },
  {
    identifier: "action",
    label: "Azione",
    var: "action",
  },
  {
    identifier: "operationCenter",
    label: "Sede",
    var: "operationCenter",
  },
  {
    identifier: "accessPoint",
    label: "Punto di accesso",
    var: "accessPoint",
  },
  {
    identifier: "type",
    label: "Tipo di validazione",
    var: "type",
  },
];
