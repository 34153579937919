import config from "../config";
import { handleResponse, authHeader } from "../_helpers";

export const staffService = {
  createTurn,
  modifyTurn,
  deleteTurn,
  getTurns,
  tempTurns,
  getStaff,
  getTurnsPdf,
  getTurnsExcel,
  getAccessPoints,
  getOperationCenters,
  getAccess,
  getAccessCount,
  getService,
  getServiceCount,
  getTasks,
};

function tempTurns() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/turnis`, requestOptions).then(handleResponse);
}

function getStaff() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/organisations/users`, requestOptions).then(
    handleResponse
  );
}

function getTurns(date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/turnis/turniMonth/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getTurnsPdf(id_user, day) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      id_user,
      day,
    }),
  };

  return fetch(`${config.api.url}/turnis/pdf`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getTurnsExcel(id_user, day) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      id_user,
      day,
    }),
  };

  return fetch(`${config.api.url}/turnis/excel`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createTurn(
  id_user,
  ora_inizio,
  ora_fine,
  giorni_ripetizione,
  mansione
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      id_user,
      ora_inizio,
      ora_fine,
      giorni_ripetizione,
      mansione,
    }),
  };

  return fetch(`${config.api.url}/turnis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyTurn(id, id_user, ora_inizio, ora_fine, mansione) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      id_user,
      ora_inizio,
      ora_fine,
      mansione,
    }),
  };

  return fetch(`${config.api.url}/turnis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteTurn(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/turnis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getAccessPoints(org) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/punto-accessos/?organisation=${org}`,
    requestOptions
  ).then(handleResponse);
}

function getOperationCenters(org) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/centro-operativos/?organisation=${org}`,
    requestOptions
  ).then(handleResponse);
}

function getTasks(org) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/mansiones/?organisation=${org}`,
    requestOptions
  ).then(handleResponse);
}

function getService(filters) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/orario-servizios/?${filters}`,
    requestOptions
  ).then(handleResponse);
}

function getServiceCount(filters) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/orario-servizios/count/?${filters}`,
    requestOptions
  ).then(handleResponse);
}

function getAccess(filters) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/accesso-utentes/?${filters}`,
    requestOptions
  ).then(handleResponse);
}

function getAccessCount(filters) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/accesso-utentes/count/?${filters}`,
    requestOptions
  ).then(handleResponse);
}
