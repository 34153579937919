import React from "react";

import Pagination from "react-js-pagination";

const Paginator = ({
  itemsPerPage,
  setItemsPerPage,
  activePage,
  setActivePage,
  totalItemsCount,
  itemsPerPageOptions,
}) => {
  //   const colors = useContext(UserContext)?.colors;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridAutoRows: "1fr",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <select
          name="options"
          style={{
            border: "1px solid lightgray",
            borderRadius: 15,
            height: 50,
            width: 70,
            padding: 10,
            textAlign: "left",
            marginRight: 10,
            marginLeft: 40,
          }}
          value={itemsPerPage}
          onChange={(event) => {
            setItemsPerPage(parseInt(event.target.value));
            setActivePage(1);
          }}
        >
          {itemsPerPageOptions?.map((option, key) => {
            return (
              <option key={key} value={option}>
                {option}
              </option>
            );
          })}
        </select>
        <p style={{ color: "gray", fontSize: 12 }}>elementi per pagina</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          onChange={(pageNumber) => setActivePage(pageNumber)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  );
};

export default Paginator;
