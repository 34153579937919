import React, { useContext, useEffect, useState } from "react";
import { OrgContext, UserContext } from "../../../contexts";
import filterIcon from "./../../../img/filter.png";
import arrowDown from "./../../../img/arrow_down.png";
import arrowUp from "./../../../img/arrow_up.png";
import ActiveFilters from "./activeFilters";
import Select from "react-select";
import { Calendar } from "react-multi-date-picker";
import { staffService, usersService } from "../../../_services";
import {
  ACTIONS,
  decode,
  formatDate,
  stringToTitleElement,
  TYPES,
} from "../config/utils";
import moment from "moment";

const Filters = ({ activeTab, setFilters, setExcelName }) => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;

  const [filterSelected, setFilterSelected] = useState();

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [actions, setActions] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [operationCenters, setOperationCenters] = useState([]);
  const [selectedOperationCenters, setSelectedOperationCenters] = useState([]);
  const [accessPoints, setAccessPoints] = useState([]);
  const [selectedAccessPoints, setSelectedAccessPoints] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const styles = {
    button: {
      backgroundColor: colors?.primary,
      width: 80,
      height: 50,
      borderRadius: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecorationLine: "none",
      border: "none",
      color: colors?.white,
      fontWeight: "800",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      padding: 10,
      marginRight: 5,
      fontFamily: "Arial",
      fontSize: "15px",
      color: "#555555",
      width: 500,
    },
    dateText: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      padding: 10,
      paddingTop: 8,
      paddingBottom: 8,
      marginRight: 5,
      fontFamily: "Arial",
      flex: 1,
      width: 200,
      cursor: "pointer",
    },
    filterSection: {
      backgroundColor: colors?.secondary,
      width: "100%",
      paddingTop: 20,
      paddingBottom: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    singleFilter: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: colors?.white,
      fontWeight: "bold",
      margin: 5,
      marginBottom: 10,
      cursor: "pointer",
      outline: "solid 1px",
      padding: 5,
      borderRadius: 5,
    },
    inputsRecap: {
      color: colors?.white,
      fontWeight: "bold",
      textAlign: "left",
    },
    filterBox: {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterButton: {
      border: "1px solid #FFFFFF",
      backgroundColor: "#FFFFFF",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 0,
      padding: 10,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
  };

  /* Fetch data */
  const fetchUsers = async () => {
    await usersService.getUsers().then((list) => setUsers(list));
  };

  const fetchActions = () => {
    setActions(ACTIONS);
  };

  const fetchOperationCenter = async () => {
    await staffService
      .getOperationCenters(org.id)
      .then((list) => setOperationCenters(list));
  };

  const fetchTypes = () => {
    setTypes(TYPES);
  };

  const getFilterComponent = (filter) => {
    switch (filter) {
      case "date":
        return (
          <div style={{ position: "absolute" }}>
            <Calendar
              value={selectedDates}
              onChange={setSelectedDates}
              range
              rangeHover
              className="teal"
            />
          </div>
        );
      case "user":
        return (
          <Select
            placeholder="Tutti gli utenti"
            value={selectedUsers}
            isMulti
            name="users"
            options={users?.map((user) => {
              return { value: user.id, label: user.username };
            })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => {
              var temp = [];
              event.forEach((element) => {
                let formattedElement = {
                  ...element,
                  ...users.find((user) => user.id === element.value),
                };
                temp.push(formattedElement);
              });
              temp.sort();
              setSelectedUsers(temp);
            }}
          />
        );
      case "action":
        return (
          <Select
            placeholder="Tutte le azioni"
            value={selectedActions}
            isMulti
            name="actions"
            options={actions?.map((action, key) => {
              return { id: key, value: action, label: decode(action) };
            })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => {
              var temp = [];
              event.forEach((element) => {
                let formattedElement = {
                  ...element,
                  ...actions.find((action) => action.id === element.value),
                };
                temp.push(formattedElement);
              });
              temp.sort();
              setSelectedActions(temp);
            }}
          />
        );
      case "operationCenter":
        return (
          <Select
            placeholder="Tutte le sedi"
            value={selectedOperationCenters}
            isMulti
            name="operationCenter"
            options={operationCenters?.map((operationCenter) => {
              return { value: operationCenter.id, label: operationCenter.name };
            })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => {
              var temp = [];
              event.forEach((element) => {
                let formattedElement = {
                  ...element,
                  ...operationCenters.find(
                    (opCen) => opCen.id === element.value
                  ),
                };
                temp.push(formattedElement);
              });
              temp.sort();
              setSelectedOperationCenters(temp);
            }}
          />
        );
      case "accessPoint":
        return (
          <Select
            placeholder="Tutte i punti di accesso"
            value={selectedAccessPoints}
            isMulti
            name="actions"
            options={accessPoints?.map((accessPoint) => {
              return { value: accessPoint?.id, label: accessPoint?.name };
            })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => {
              var temp = [];
              event.forEach((element) => {
                let formattedElement = {
                  ...element,
                  ...accessPoints.find(
                    (accessPoint) => accessPoint.id === element.value
                  ),
                };
                temp.push(formattedElement);
              });
              temp.sort();
              setSelectedAccessPoints(temp);
            }}
          />
        );
      case "type":
        return (
          <Select
            placeholder="Tutte le modalità di registrazione"
            value={selectedTypes}
            isMulti
            name="actions"
            options={types?.map((type, key) => {
              return { id: key, value: type, label: decode(type) };
            })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(event) => {
              var temp = [];
              event.forEach((element) => {
                let formattedElement = {
                  ...element,
                  ...types.find((type) => type.id === element.value),
                };
                temp.push(formattedElement);
              });
              temp.sort();
              setSelectedTypes(temp);
            }}
          />
        );
      default:
        return null;
    }
  };

  /* Format filters */
  const formatFilter = () => {
    let dateFilter =
      "&" +
      activeTab.filters.find((fil) => fil.identifier === "date")?.var +
      "=" +
      selectedDates
        .map((date) => {
          return new Date(date).toISOString();
        })
        .join(",");
    let userFilter =
      "&user=" +
      selectedUsers
        .map((user) => {
          return user.id;
        })
        .join(",");
    let actionFilter =
      "&action=" +
      selectedActions
        .map((action) => {
          return action.value;
        })
        .join(",");

    let operationCenterFilter =
      "&operationCentre=" +
      selectedOperationCenters
        .map((operationCenter) => {
          return operationCenter.id;
        })
        .join(",");
    let accessPointFilter =
      "&accessPoint=" +
      selectedAccessPoints
        .map((accessPoint) => {
          return accessPoint.id;
        })
        .join(",");
    let typeFilter =
      "&type=" +
      selectedTypes
        .map((type) => {
          return type.value;
        })
        .join(",");
    let filters =
      (activeTab.filters.some((fil) => fil.identifier === "date") &&
      selectedDates.length > 1
        ? dateFilter
        : "") +
      (activeTab.filters.some((fil) => fil.identifier === "user") &&
      !!selectedUsers.length
        ? userFilter
        : "") +
      (activeTab.filters.some((fil) => fil.identifier === "action") &&
      !!selectedActions.length
        ? actionFilter
        : "") +
      (activeTab.filters.some((fil) => fil.identifier === "operationCenter") &&
      !!selectedOperationCenters.length
        ? operationCenterFilter
        : "") +
      (activeTab.filters.some((fil) => fil.identifier === "accessPoint") &&
      !!selectedAccessPoints.length
        ? accessPointFilter
        : "") +
      (activeTab.filters.some((fil) => fil.identifier === "type") &&
      !!selectedTypes.length
        ? typeFilter
        : "");
    setFilters(filters);
  };

  /* Remove filter */
  const removeFilter = (filter) => {
    // for every array remove the correspondent filter
    let filteredData;
    if (filter.label === "Data") {
      filteredData = [];
    } else {
      filteredData = filter.data.filter((item) => {
        return item.id !== filter.id;
      });
    }
    // if the filter was removed, update the array
    if (filteredData.length !== filter.data.length) {
      filter.setter(filteredData);
    }
  };

  const filterLabel = (fil, key) => {
    return (
      <div
        style={{
          ...styles.singleFilter,
          outline:
            fil.identifier === filterSelected?.identifier
              ? "solid 2px"
              : "solid 1px",
          opacity: 1,
          backgroundColor:
            fil.identifier === filterSelected?.identifier
              ? colors.tertiary
              : "transparent",
        }}
        onClick={(e) => {
          setFilterSelected(
            fil.identifier === filterSelected?.identifier ? null : fil
          );
        }}
        key={key}
      >
        <p
          style={{
            margin: "5px 10px",
            fontWeight:
              fil.identifier === filterSelected?.identifier ? "500" : "normal",
          }}
        >
          {fil.label}
        </p>
        <img
          alt="Arrow icon"
          src={
            fil.identifier === filterSelected?.identifier ? arrowUp : arrowDown
          }
          style={{ width: 10, objectFit: "contain", marginRight: 10 }}
        />
      </div>
    );
  };

  const getExcelName = () => {
    var tempName = "Presenze" + "_" + activeTab?.title;

    if (!!selectedDates.length) {
      tempName = tempName + "_Periodo";
      selectedDates.map((date) => {
        return (tempName = tempName + "-" + formatDate(date, "date"));
      });
    }

    if (!!selectedUsers.length) {
      tempName = tempName + "_Dipendente";
      selectedUsers.map((user) => {
        return (tempName = tempName + "-" + stringToTitleElement(user?.label));
      });
    }

    if (!!selectedActions.length) {
      tempName = tempName + "_Azione";
      selectedActions.map((action) => {
        return (tempName =
          tempName + "-" + stringToTitleElement(action?.label));
      });
    }

    if (!!selectedOperationCenters.length) {
      tempName = tempName + "_Sede";
      selectedOperationCenters.map((operationCentre) => {
        return (tempName =
          tempName + "-" + stringToTitleElement(operationCentre?.name));
      });
    }

    if (!!selectedAccessPoints.length) {
      tempName = tempName + "_PuntoAccesso";
      selectedAccessPoints.map((accessPoint) => {
        return (tempName =
          tempName + "-" + stringToTitleElement(accessPoint?.name));
      });
    }

    if (!!selectedTypes.length) {
      tempName = tempName + "_TipoValidazione";
      selectedTypes.map((type) => {
        return (tempName = tempName + "-" + stringToTitleElement(type?.label));
      });
    }

    setExcelName(tempName);
  };

  useEffect(() => {
    // fetch related access points whenever an operation center is selected
    const accessPointsList = selectedOperationCenters
      .map((opCenter) => {
        return opCenter.accessPoints;
      })
      .flat();
    setAccessPoints(accessPointsList);

    // remove deleted accessPoints from selected
    //  (linked to an operation center removed from selected)
    var tempSelectedAccessPoints = [...selectedAccessPoints];
    tempSelectedAccessPoints = tempSelectedAccessPoints.filter((sel) =>
      accessPointsList.some((item) => item.id === sel.id)
    );
    setSelectedAccessPoints(tempSelectedAccessPoints);
  }, [selectedOperationCenters]);

  /* Format arrays and combine generic selectedFilters*/
  useEffect(() => {
    formatFilter(); // Format and updates filters in parent
    getExcelName(); // Generate excel name and updates in parent

    const combinedFilters = [
      ...(selectedDates.length > 1
        ? [
            {
              id: "date",
              label: "Data",
              value:
                "da " +
                moment(new Date(selectedDates[0])).format("DD/MM/YYYY") +
                " a " +
                moment(new Date(selectedDates[1])).format("DD/MM/YYYY"),
              data: selectedDates,
              setter: setSelectedDates,
            },
          ]
        : []),
      ...selectedUsers.map((user) => ({
        id: user.id,
        label: "Dipendente",
        value: user.username,
        data: selectedUsers,
        setter: setSelectedUsers,
      })),
      ...selectedActions.map((action) => ({
        id: action.id,
        label: "Azione",
        value: action.label,
        data: selectedActions,
        setter: setSelectedActions,
      })),
      ...selectedOperationCenters.map((operationCenter) => ({
        id: operationCenter.id,
        label: "Sede",
        value: operationCenter.name,
        data: selectedOperationCenters,
        setter: setSelectedOperationCenters,
      })),
      ...selectedAccessPoints.map((accessPoint) => ({
        id: accessPoint.id,
        label: "Punto di accesso",
        value: accessPoint.name,
        data: selectedAccessPoints,
        setter: setSelectedAccessPoints,
      })),
      ...selectedTypes.map((type) => ({
        id: type.id,
        label: "Tipo di validazione",
        value: type.label,
        data: selectedTypes,
        setter: setSelectedTypes,
      })),
    ];

    setSelectedFilters(combinedFilters);
  }, [
    selectedDates,
    selectedUsers,
    selectedActions,
    selectedOperationCenters,
    selectedAccessPoints,
    selectedTypes,
  ]);

  useEffect(() => {
    setFilterSelected();
    setSelectedFilters([]);
    if (!!selectedDates.length) setSelectedDates([]);
    if (!!selectedUsers.length) setSelectedUsers([]);
    if (!!selectedActions.length) setSelectedActions([]);
    if (!!selectedOperationCenters.length) setSelectedOperationCenters([]);
    if (!!selectedAccessPoints.length) setSelectedAccessPoints([]);
    if (!!selectedTypes.length) setSelectedTypes([]);
  }, [activeTab]);

  useEffect(() => {
    fetchUsers();
    fetchOperationCenter();
    fetchActions();
    fetchTypes();
  }, []);

  return (
    <div style={styles.filterSection}>
      <div
        style={{
          width: "90%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                alt="Filter icon"
                src={filterIcon}
                style={{ width: 10, objectFit: "contain" }}
              />
              <p
                style={{
                  color: colors?.white,
                  fontWeight: "bold",
                  margin: 0,
                  marginLeft: 10,
                }}
              >
                FILTRA
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {activeTab.filters?.map((filter, key) => {
                if (
                  !(
                    filter.identifier === "accessPoint" &&
                    selectedOperationCenters.length === 0
                  )
                ) {
                  return filterLabel(filter, key);
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </div>
        <div style={styles.filterBox}>
          {getFilterComponent(filterSelected?.identifier)}
        </div>
        <ActiveFilters filters={selectedFilters} removeFilter={removeFilter} />
      </div>
    </div>
  );
};

export default Filters;
