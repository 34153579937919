import React, { useContext } from "react";
import { UserContext } from "../../../contexts";
import closeIcon from "../../../img/closeButton.png";

const ActiveFilters = ({ filters, removeFilter }) => {
  const colors = useContext(UserContext)?.colors;

  const styles = {
    filterBar: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      paddingBlock: "10px",
      marginTop: 5,
      borderRadius: "10px",
    },
    filterBox: {
      backgroundColor: colors.primary,
      color: "white",
      padding: "8px 8px 8px 14px",
      borderRadius: "20px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
      fontSize: "12px",
    },
    removeButton: {
      background: "none",
      border: "none",
      color: "white",
      cursor: "pointer",
      fontSize: "12px",
    },
  };

  return (
    <div style={styles.filterBar}>
      {filters.map((filter) => (
        <div key={filter.id} style={styles.filterBox}>
          <span>
            {filter.label}: <b>{filter.value}</b>
          </span>
          <button
            onClick={() => removeFilter(filter)}
            style={styles.removeButton}
            title="Rimuovi"
          >
            <img
              alt="Close button"
              src={closeIcon}
              style={{ width: 8, objectFit: "contain" }}
            />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ActiveFilters;
