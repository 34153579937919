import React, { useContext, useEffect, useState, useRef } from "react";
import Filters from "./component/filters";
import { staffService } from "../../_services";
import { OrgContext, UserContext } from "../../contexts";
import Table from "./component/table";
import Paginator from "./component/pagination";
import "react-multi-date-picker/styles/colors/teal.css";
import { serviceFields } from "./config/service";
import { accessFields } from "./config/access";
import {
  convertMilliseconds,
  convertToDecimalHours,
  decode,
  ITEMS_PER_PAGE,
  TABS,
} from "./config/utils";
import download from "../../img/download.png";
import excel from "../../img/excel_dark.png";
import ReactLoading from "react-loading";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TimeMarker = () => {
  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;

  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE[0]);
  const prevItemsPerPage = useRef();
  const [activePage, setActivePage] = useState(1);
  const [order, setOrder] = useState("");

  const prevActivePage = useRef();

  const [totalItemsCount, setTotalItemsCount] = useState(undefined);

  const [accessList, setAccessList] = useState([]);
  const [allAccessList, setAllAccessList] = useState([]);

  const [serviceList, setServiceList] = useState([]);
  const [allServiceList, setAllServiceList] = useState([]);

  const [filters, setFilters] = useState();
  const [excelName, setExcelName] = useState();

  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [loading, setLoading] = useState(false);

  const styles = {
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    filterBox: {
      flex: 1,
    },
    tableBox: {
      flex: 7,
      margin: 30,
      marginBottom: 0,
      overflow: "scroll",
    },
    paginatorBox: {
      flex: 1,
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    tabs: {
      display: "flex",
      width: "100%",
    },
    tabButton: {
      flex: 1,
      cursor: "pointer",
      backgroundColor: colors.quaternary,
      border: "none",
      padding: 20,
    },
    tabButtonActive: {
      backgroundColor: colors.primary,
      color: "white",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    downloadButton: {
      border: "none",
      outline: "none",
      backgroundColor: colors.darkgray,
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  /* Format filters */
  const getFilters = (isReport) => {
    let filtersWithPagination =
      (!!org ? "organisation=" + org.id : "") +
      (!!activePage ? "&page=" + (isReport === true ? 1 : activePage) : "") +
      (!!itemsPerPage
        ? "&limit=" + (isReport === true ? "-1" : itemsPerPage)
        : "") +
      (!!order ? "&sort=" + order : "") +
      (!!filters ? filters : "");
    return filtersWithPagination;
  };

  /* Fetch table list */
  const fetchService = (isReport) => {
    let filters = getFilters(isReport);
    setLoading(true);

    staffService.getService(filters).then((list) => {
      let formattedList = list.map((item) => {
        let decodedItem = {};

        serviceFields.forEach((key) => {
          if (key.value === "timestamp") {
            decodedItem[key.value] = decode(item["actualStart"], "date");
          }
          if (key.value === "workingHours") {
            if (!!item.actualStart && !!item.actualEnd) {
              let total;
              const start = new Date(item.actualStart);
              const end = new Date(item.actualEnd);
              const totalBreak = item.break
                ?.map((br) => {
                  const start = new Date(br.actualStart);
                  const end = new Date(br.actualEnd);
                  if (!!start && !!end) return end - start;
                  else return 0;
                })
                .reduce((acc, num) => acc + num, 0);
              if (!!start && !!end) total = end - start - totalBreak;
              const difference = convertMilliseconds(total);

              decodedItem[key.value] =
                String(difference.hours) +
                "h " +
                String(difference.minutes) +
                "min";
            } else {
              decodedItem[key.value] = "-";
            }
          }
          if (key.subvalue) {
            if (
              item.hasOwnProperty(key.value) &&
              typeof item[key.value] === "object"
            ) {
              decodedItem[key.value] = decodedItem[key.value] || {};

              if (item[key.value].hasOwnProperty(key.subvalue)) {
                decodedItem[key.value][key.subvalue] = decode(
                  item[key.value][key.subvalue],
                  key.format
                );
              } else if (Array.isArray(item[key.value])) {
                decodedItem[key.value][key.subvalue] = item[key.value]
                  .map((el) => {
                    return decode(el[key.subvalue], key.format);
                  })
                  .join();
              }
            }
          } else {
            if (item.hasOwnProperty(key.value)) {
              decodedItem[key.value] = decode(item[key.value], key.format);
            }
          }
        });

        return decodedItem;
      });

      if (isReport) {
        setAllServiceList(formattedList);
      } else {
        setServiceList(formattedList);
      }

      setLoading(false);
    });
  };

  const fetchServiceCount = () => {
    let filters = getFilters();
    staffService
      .getServiceCount(filters)
      .then((total) => setTotalItemsCount(total));
  };

  const fetchAccess = (isReport) => {
    let filters = getFilters(isReport);
    setLoading(true);

    staffService.getAccess(filters).then((list) => {
      let formattedList = list.map((item) => {
        let decodedItem = {};

        accessFields.forEach((key) => {
          if (key.subvalue) {
            if (
              item.hasOwnProperty(key.value) &&
              typeof item[key.value] === "object"
            ) {
              decodedItem[key.value] = decodedItem[key.value] || {};

              if (item[key.value].hasOwnProperty(key.subvalue)) {
                decodedItem[key.value][key.subvalue] = decode(
                  item[key.value][key.subvalue],
                  key.format
                );
              }
            }
          } else {
            if (item.hasOwnProperty(key.value)) {
              decodedItem[key.value] = decode(item[key.value], key.format);
            }
          }
        });

        return decodedItem;
      });

      if (isReport) {
        setAllAccessList(formattedList);
      } else {
        setAccessList(formattedList);
      }

      setLoading(false);
    });
  };

  const fetchAccessCount = () => {
    let filters = getFilters();
    staffService
      .getAccessCount(filters)
      .then((total) => setTotalItemsCount(total));
  };

  /* Generate Excel */

  const createReport = () => {
    if (!!activeTab) {
      if (activeTab.id === 0) {
        fetchService(true);
      } else {
        fetchAccess(true);
      }
    }
  };
  const getDataSet = () => {
    const excelColumns =
      activeTab.id === 0
        ? serviceFields.map((item) => item.label)
        : accessFields.map((item) => item.label);

    const excelData =
      activeTab.id === 0
        ? allServiceList.map((item) => {
            const res = [
              item.timestamp,
              item.user?.username,
              item.actualStart,
              item.actualEnd,
              item.break !== "-" ? convertToDecimalHours(item.break) : 0,
              item.workingHours !== "-"
                ? convertToDecimalHours(item.workingHours)
                : 0,
              item.tasks?.title,
            ];
            return res;
          })
        : allAccessList.map((item) => {
            const res = [
              item.timestamp,
              item.user?.username,
              item.action,
              item.operationCentre?.name,
              item.accessPoint?.name,
              item.type,
            ];
            return res;
          });

    return [
      {
        columns: excelColumns,
        data: excelData,
      },
    ];
  };

  /* Fetch updated list */
  useEffect(() => {
    // set active page to 1, unless what is changed was actually the page
    if (prevActivePage.current === activePage) {
      setActivePage(1);
    }

    if (!!activeTab) {
      if (activeTab.id === 0) {
        if (
          prevActivePage.current === activePage &&
          prevItemsPerPage.current === itemsPerPage
        )
          setAllServiceList([]);
        fetchService();
        fetchServiceCount();
      } else {
        if (
          prevActivePage.current === activePage &&
          prevItemsPerPage.current === itemsPerPage
        )
          setAllAccessList([]);
        fetchAccess();
        fetchAccessCount();
      }
    }
    prevActivePage.current = activePage;
    prevItemsPerPage.current = itemsPerPage;
  }, [activeTab, activePage, itemsPerPage, order, filters]);

  return !!activeTab ? (
    <div style={styles.container}>
      <div style={styles.filterBox}>
        <Filters
          activeTab={activeTab}
          setFilters={setFilters}
          setExcelName={setExcelName}
        />
      </div>
      <div style={styles.tabs}>
        {TABS.map((tab, key) => {
          return (
            <button
              key={key}
              style={{
                ...styles.tabButton,
                backgroundColor:
                  activeTab.id === tab.id ? colors.quaternary : colors.tertiary,
                color: activeTab.id === tab.id ? "black" : "white",
              }}
              onClick={() => {
                setFilters();
                setActiveTab(tab);
              }}
            >
              {tab.title}
            </button>
          );
        })}
      </div>

      {loading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 30,
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={colors.darkgray}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <>
          <div style={styles.tableBox}>
            <div
              style={{
                ...styles.row,
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 10,
              }}
            >
              <p
                style={{
                  color: colors?.gray,
                  fontSize: 11,
                  textAlign: "left",
                }}
              >
                {totalItemsCount +
                  " " +
                  (totalItemsCount > 1 || totalItemsCount === 0
                    ? "risultati trovati"
                    : "risultato trovato")}
              </p>

              {/* Actual download button */}
              {(activeTab.id === 0 && allServiceList.length > 0) ||
              (activeTab.id === 1 && allAccessList.length > 0) ? (
                <ExcelFile
                  filename={excelName}
                  element={
                    <button
                      style={styles.downloadButton}
                      title="Scarica report Excel"
                    >
                      <img
                        alt="Download icon"
                        src={download}
                        style={{ width: 15, objectFit: "contain" }}
                      />
                    </button>
                  }
                >
                  <ExcelSheet dataSet={getDataSet()} name={activeTab.title} />
                </ExcelFile>
              ) : (
                // Prepare Excel button
                ((activeTab.id === 0 && serviceList.length > 0) ||
                  (activeTab.id === 1 && accessList.length > 0)) && (
                  <button
                    style={{
                      ...styles.downloadButton,
                      backgroundColor: colors?.lightgray,
                    }}
                    title="Crea report Excel"
                    onClick={() => {
                      createReport();
                    }}
                  >
                    <img
                      alt="Excel icon"
                      src={excel}
                      style={{ width: 20, objectFit: "contain" }}
                    />
                  </button>
                )
              )}
            </div>
            {activeTab.id === 0 &&
              (serviceList.length > 0 ? (
                <Table
                  list={serviceList}
                  fields={serviceFields}
                  colors={colors}
                  order={order}
                  setOrder={setOrder}
                />
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    color: colors.gray,
                    marginTop: 40,
                  }}
                >
                  Nessuna risultato corrispondente ai criteri della ricerca
                </p>
              ))}
            {activeTab.id === 1 &&
              (accessList.length > 0 ? (
                <Table
                  list={accessList}
                  fields={accessFields}
                  colors={colors}
                  order={order}
                  setOrder={setOrder}
                />
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    color: colors.gray,
                    marginTop: 40,
                  }}
                >
                  Nessuna risultato corrispondente ai criteri della ricerca
                </p>
              ))}
          </div>
          {((activeTab.id === 0 && serviceList.length > 0) ||
            (activeTab.id === 1 && accessList.length > 0)) && (
            <div style={styles.paginatorBox}>
              <Paginator
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                activePage={activePage}
                setActivePage={setActivePage}
                totalItemsCount={totalItemsCount}
                itemsPerPageOptions={ITEMS_PER_PAGE}
              />
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <p>Loading</p>
  );
};

export default TimeMarker;
