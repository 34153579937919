import React, { useCallback, useContext, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { staffService, eventsService } from "../../_services";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./../../index.css";
import close from "./../../img/closeButton.png";
import Week from "../../components/week";
import TurnInfo from "./turnInfo";
import ReactLoading from "react-loading";
import RemoveTurn from "./popup/removeTurn";
import DownloadButton from "../../components/buttons/downloadButton";
import { UserContext } from "../../contexts";
import CopyTurn from "./popup/copyTurn";

const localizer = momentLocalizer(moment);

function ShiftSchedule(props) {
  const [dateSelected, setDateSelected] = useState();
  const [timeTable, setTimeTable] = useState([]);
  const [onSelecting, setOnSelecting] = useState(false);
  const [closingDays, setClosingDays] = useState([]);
  const [startingTime, setStartingTime] = useState(new Date());
  const [endingTime, setEndingTime] = useState();
  const [task, setTask] = useState();
  const [staffList, setStaffList] = useState([]);
  const [turnsSelected, setTurnsSelected] = useState([]);
  const [lunedi, setLunedi] = useState(false);
  const [martedi, setMartedi] = useState(false);
  const [mercoledi, setMercoledi] = useState(false);
  const [giovedi, setGiovedi] = useState(false);
  const [venerdi, setVenerdi] = useState(false);
  const [sabato, setSabato] = useState(false);
  const [domenica, setDomenica] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [filter, setFilter] = useState(-1);
  const [loader, setLoader] = useState(true);
  const [loaderTurn, setLoaderTurn] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [showDeleteTurn, setShowDeleteTurn] = useState(false);
  const [showCopyTurn, setShowCopyTurn] = useState(false);
  const [turnToDelete, setTurnToDelete] = useState();
  const [turnToCopy, setTurnToCopy] = useState();
  const [currentMonth, setCurrentMonth] = useState();
  const [operator, setOperator] = useState();

  const [timeError, setTimeError] = useState(false);

  const [modifyLoader, setModifyLoader] = useState(false);

  const height = useState(window.innerHeight);

  let total = 0;

  const colors = useContext(UserContext)?.colors;

  const getClosingDays = useCallback(
    (date) => {
      eventsService
        .getClosingDay(date)
        .then((closingDays) => {
          setClosingDays(closingDays);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            props.history.push("/");
          }
        });
    },
    [props.history]
  );

  const getTurns = useCallback(
    (date) => {
      staffService
        .getTurns(date)
        .then((turns) => {
          let temp = turns;
          for (let i = 0; i < temp.length; i++) {
            temp[i].ora_inizio = new Date(temp[i].ora_inizio);
            temp[i].ora_fine = new Date(temp[i].ora_fine);
          }
          setTimeTable(temp);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            props.history.push("/");
          }
        });
    },
    [props.history]
  );

  const onNavigate = useCallback(
    (date) => {
      setDateSelected(date);
      setCurrentMonth(new Date(date).getMonth());
      getClosingDays(moment(date).format("yyyy-MM-DD"));
      getTurns(moment(date).format("yyyy-MM-DD"));
    },
    [getClosingDays, getTurns]
  );

  const onSelectDate = useCallback(
    (date) => {
      if (new Date(date).getMonth() !== new Date(dateSelected).getMonth()) {
        getTurns(moment(date).format("yyyy-MM-DD"));
      }
      setDateSelected(date);
      setTurnsSelected(
        timeTable.filter(
          (turn) =>
            moment(turn.ora_inizio).format("yyyy-MM-DD") ===
            moment(date).format("yyyy-MM-DD")
        )
      );
      if (closingDays.indexOf(moment(date).format("yyyy-MM-DD")) !== -1) {
        setIsClosed(true);
      } else {
        setIsClosed(false);
      }
    },
    [closingDays, dateSelected, getTurns, timeTable]
  );

  const updateTurnsSelected = useCallback(() => {
    let filteredTimeTable = [];
    if (filter !== -1) {
      filteredTimeTable = timeTable.filter((turn) => turn.id_user === filter);
    } else {
      filteredTimeTable = timeTable;
    }
    setTurnsSelected(
      filteredTimeTable.filter(
        (turn) =>
          moment(turn.ora_inizio).format("yyyy-MM-DD") ===
          moment(dateSelected).format("yyyy-MM-DD")
      )
    );
  }, [dateSelected, filter, timeTable]);

  const getPdf = () => {
    setLoader(true);
    staffService
      .getTurnsPdf(filter, moment(dateSelected).format("yyyy-MM-DD"))
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file.url;
        a.target = "_blank";
        a.download = "orario.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const getExcel = () => {
    setLoader(true);
    staffService
      .getTurnsExcel(filter, moment(dateSelected).format("yyyy-MM-DD"))
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file.url;
        a.download = "orario.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const modifyTurn = (id, user, starting, ending, task) => {
    setModifyLoader(true);
    staffService
      .modifyTurn(id, user, starting, ending, task)
      .then((response) => {
        let temp = response;
        for (let i = 0; i < temp.length; i++) {
          temp[i].ora_inizio = new Date(temp[i].ora_inizio);
          temp[i].ora_fine = new Date(temp[i].ora_fine);
        }
        setTimeTable(temp);
        updateTurnsSelected();
        setModifyLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const toggleCopyTurn = (turn, user, starting, ending, task) => {
    // setOnSelecting(!onSelecting);
    setShowCopyTurn(!showCopyTurn);
    setTurnToCopy(turn);
    setOperator(user);
    setStartingTime(starting);
    setEndingTime(ending);
    setTask(task);
  };

  const copyTurn = (newDate) => {
    let newStarting = startingTime;
    let newEnding = endingTime;

    newStarting.setFullYear(newDate.getFullYear());
    newStarting.setMonth(newDate.getMonth());
    newStarting.setDate(newDate.getDate());

    newEnding.setFullYear(newDate.getFullYear());
    newEnding.setMonth(newDate.getMonth());
    newEnding.setDate(newDate.getDate());

    let giorni_ripetizione = {
      lunedi,
      martedi,
      mercoledi,
      giovedi,
      venerdi,
      sabato,
      domenica,
    };
    staffService
      .createTurn(operator, newStarting, newEnding, giorni_ripetizione, task)
      .then((turns) => {
        let temp = turns;
        for (let i = 0; i < temp.length; i++) {
          temp[i].ora_inizio = new Date(temp[i].ora_inizio);
          temp[i].ora_fine = new Date(temp[i].ora_fine);
        }
        setTimeTable(temp);
        resetCreateTurn();
        setTurnsSelected(
          timeTable.filter(
            (turn) =>
              moment(turn.ora_inizio).format("yyyy-MM-DD") ===
              moment(dateSelected).format("yyyy-MM-DD")
          )
        );
        setLoaderTurn(false);
        setShowCopyTurn(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const deleteTurn = () => {
    staffService
      .deleteTurn(turnToDelete.id)
      .then((response) => {
        let temp = response;
        for (let i = 0; i < temp.length; i++) {
          temp[i].ora_inizio = new Date(temp[i].ora_inizio);
          temp[i].ora_fine = new Date(temp[i].ora_fine);
        }
        setTimeTable(temp);
        setShowDeleteTurn(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const createTurn = () => {
    let giorni_ripetizione = {
      lunedi,
      martedi,
      mercoledi,
      giovedi,
      venerdi,
      sabato,
      domenica,
    };
    staffService
      .createTurn(operator, startingTime, endingTime, giorni_ripetizione, task)
      .then((turns) => {
        let temp = turns;
        for (let i = 0; i < temp.length; i++) {
          temp[i].ora_inizio = new Date(temp[i].ora_inizio);
          temp[i].ora_fine = new Date(temp[i].ora_fine);
        }
        setTimeTable(temp);
        resetCreateTurn();
        setLoaderTurn(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onSelectSlot = (slot) => {
    setDateSelected(slot.start);
    setIsClosed(false);
    if (closingDays.indexOf(moment(slot.start).format("yyyy-MM-DD")) !== -1) {
      setIsClosed(true);
    }
    if (
      closingDays.indexOf(moment(slot.start).format("yyyy-MM-DD")) !== -1 ||
      new Date(slot.start) < new Date()
    ) {
      setOnSelecting(false);
    } else {
      setOnSelecting(true);
      setStartingTime(slot.start);
      setEndingTime(slot.end);
    }
  };

  const isRepeated = () => {
    return (
      lunedi || martedi || mercoledi || giovedi || venerdi || sabato || domenica
    );
  };

  const resetCreateTurn = () => {
    setOperator(staffList[0].id);
    setTask();
    setStartingTime(new Date());
    setEndingTime(new Date());
    setLunedi(false);
    setMartedi(false);
    setMercoledi(false);
    setGiovedi(false);
    setVenerdi(false);
    setSabato(false);
    setDomenica(false);
    setOnSelecting(false);
  };

  const toggleAddTurn = () => {
    resetCreateTurn();
    if (onSelecting) {
      let temp = new Date(dateSelected);
      temp.setHours(0, 0, 0, 0);
      setStartingTime(temp);
      setEndingTime(temp);
    }
    setOnSelecting(!onSelecting);
  };

  useEffect(() => {
    setTimeError(
      startingTime !== "" &&
        endingTime !== "" &&
        (moment(startingTime).format("HH:mm") !== "00:00" ||
          moment(endingTime).format("HH:mm") !== "00:00") &&
        startingTime >= endingTime
    );
  }, [startingTime, endingTime]);

  const preventTurnCreation = () => {
    return timeError || task === "";
  };

  const toggleDeleteTurn = (turn, schemaEvent) => {
    setShowDeleteTurn(!showDeleteTurn);
    setTurnToDelete(turn);
  };

  const calculateTimeDifference = (startTime, endTime) => {
    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));
    // duration in minutes
    var minutes = parseInt(duration.asMinutes());
    return minutes;
  };

  const withoutTime = (date) => {
    var d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  };

  useEffect(() => {
    updateTurnsSelected();
  }, [updateTurnsSelected]);

  useEffect(() => {
    setCurrentMonth(new Date().getMonth());
    onNavigate(new Date());
    staffService
      .getStaff()
      .then((staff) => {
        setStaffList(staff);
        setOperator(staff[0].id);
        setLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  }, [props.history, onNavigate]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      width: props.iframe ? "100%" : "88%",
      height: "100%",
      alignItems: "center",
      justifyContent: "space-around",
    },
    subcontainer: {
      height: "100%",
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    calendarBox: {
      display: "flex",
      flex: 6,
      flexDirection: "column",
      alignItems: "center",
      margin: 25,
    },
    infoBox: {
      flex: 1,
      height: "100%",
      backgroundColor: colors.tertiary,
    },
    header: {
      height: 100,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    body: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      fontSize: 15,
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    label: {
      color: colors.secondary,
      width: 80,
      fontSize: 13,
      textAlign: "left",
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: "80%",
    },
    list: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflowY: "scroll",
    },
    menu: {
      border: "1px solid lightgray",
      width: "100%",
      padding: 10,
      color: colors.darkgray,
      borderRadius: 10,
      backgroundColor: colors.white,
      outline: "none",
    },
    userBox: {
      width: 200,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      fontSize: 15,
      justifyContent: "space-between",
      alignItems: "sapace-between",
    },
  };

  return (
    <div style={styles.container}>
      {loader ? (
        <ReactLoading
          type={"spinningBubbles"}
          color={colors.primary}
          height={50}
          width={50}
        />
      ) : (
        <div style={styles.subcontainer}>
          <div style={styles.calendarBox}>
            <select
              name="filter"
              style={{ ...styles.menu, marginBottom: 30 }}
              value={filter}
              onChange={(event) => {
                setFilter(parseInt(event.target.value));
              }}
            >
              <option value={-1}>{"Tutti gli operatori"}</option>
              {staffList.map((user, key) => {
                return (
                  <option key={key} value={user.id}>
                    {user.username}
                  </option>
                );
              })}
            </select>
            <Calendar
              date={moment(dateSelected).toDate()}
              localizer={localizer}
              events={
                filter !== -1
                  ? timeTable.filter((turn) => turn.id_user === filter)
                  : timeTable
              }
              titleAccessor="nome_user"
              startAccessor="ora_inizio"
              endAccessor="ora_fine"
              resourceAccessor="id"
              selectable={true}
              onNavigate={(date) => onNavigate(date)}
              onDrillDown={(date) => onSelectDate(date)}
              onSelectSlot={(slot) => onSelectSlot(slot)}
              onSelectEvent={(event) => {
                setIdSelected(event.id);
                onSelectDate(event.ora_inizio);
              }}
              messages={{
                next: "Successivo",
                previous: "Precedente",
                today: "Oggi",
                month: "Mese",
                week: "Settimana",
                day: "Giorno",
              }}
              views={["month", "week", "day"]}
              popup={true}
              scrollToTime={new Date()}
              style={{
                height: "70%",
                width: "100%",
                marginTop: 30,
              }}
              eventPropGetter={(event, start, end, isSelected) => {
                let newStyle = {
                  backgroundColor: isSelected
                    ? colors.primary
                    : colors.tertiary,
                  color: isSelected ? colors.white : colors.primary,
                  borderRadius: 5,
                  outline: "none",
                  border: "2px solid #ffffff",
                  fontSize: 13,
                };
                return {
                  className: "",
                  style: newStyle,
                };
              }}
              dayPropGetter={(day) => {
                let newStyle = {
                  backgroundColor: "#f6f6f6",
                  color: colors.white,
                  opacity: 1,
                };

                if (
                  (closingDays.indexOf(moment(day).format("yyyy-MM-DD")) !==
                    -1 &&
                    new Date(day).getMonth() === currentMonth) ||
                  moment(day).add(1, "days") < moment()
                ) {
                  return {
                    className: "",
                    style: newStyle,
                  };
                } else {
                  return null;
                }
              }}
            />
            {/* <div style={styles.footer}>
              <div style={{ margin: 20 }}>
                <p style={{ fontSize: 15, color: colors.secondary, margin: 0 }}>
                  Piano ore
                </p>
                <p
                  style={{
                    fontSize: 70,
                    fontWeight: "bold",
                    color: colors.secondary,
                    margin: 0,
                  }}
                >
                  {moment(new Date(2000, currentMonth, 1)).format("MMM")}
                </p>
                <p style={{ fontSize: 25, color: colors.secondary, margin: 0 }}>
                  {moment(dateSelected).format("yyyy")}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                <div
                  style={{
                    margin: 5,
                    width: "calc(100vw - 760px)",
                    overflowX: "scroll",
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 15,
                    alignItems: "flex-end",
                  }}
                  id="scroll"
                >
                  {staffList.map((op, key) => {
                    let partial = 0;
                    timeTable
                      .filter((turn) => turn.id_user === op.id)
                      .map(
                        (filteredTurn) =>
                          (partial += calculateTimeDifference(
                            moment(filteredTurn.ora_inizio, "HH:mm:ss a"),
                            moment(filteredTurn.ora_fine, "HH:mm:ss a")
                          ))
                      );
                    let hour = partial / 60;
                    let minutes = partial % 60;
                    total += partial;
                    return (
                      <div key={key} style={styles.userBox}>
                        <p
                          style={{
                            flex: 1,
                            margin: 5,
                            // marginTop: 20,
                            fontStyle: "oblique",
                            opacity: 0.7,
                          }}
                        >
                          {op.username}
                        </p>
                        <p
                          style={{
                            flex: 1,
                            fontSize: 20,
                            margin: 0,
                            opacity: 0.7,
                          }}
                        >
                          {Math.floor(hour) + " h"}
                          {minutes !== 0 && " " + minutes + " m"}
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    backgroundColor: colors.secondary,
                    width: "calc(100vw - 860px)",
                    borderRadius: 10,
                    color: colors.white,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 50,
                    marginBottom: 15,
                  }}
                >
                  <p style={{ marginRight: 40, fontSize: 15 }}>Totale </p>
                  <p style={{ fontSize: 30, fontWeight: "bold" }}>
                    {Math.floor(total / 60) + " h"}
                    {total % 60 !== 0 && " " + (total % 60) + " m"}
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          <div style={styles.infoBox}>
            <div style={{ ...styles.header, marginTop: 30 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "baseline",
                }}
              >
                <p
                  style={{
                    fontSize: 70,
                    fontWeight: "bold",
                    color: colors.white,
                    margin: 0,
                    marginRight: 10,
                  }}
                >
                  {moment(dateSelected).format("D")}
                </p>
                <p
                  style={{
                    fontSize: 25,
                    color: colors.white,
                    margin: 0,
                    marginRight: 20,
                  }}
                >
                  {moment(dateSelected).format("MMMM")}
                </p>
              </div>
              <p
                style={{
                  fontSize: 15,
                  color: colors.white,
                  margin: 0,
                  marginRight: 20,
                }}
              >
                {moment(dateSelected).format("dddd")}
              </p>
            </div>
            {loaderTurn ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={colors.primary}
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <>
                {isClosed && (
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: colors.secondary,
                      color: colors.white,
                      fontWeight: "bold",
                      paddingTop: 20,
                      paddingBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    CHIUSO
                  </div>
                )}
                <div style={styles.body} id="scroll">
                  {!onSelecting &&
                    withoutTime(dateSelected) >= withoutTime(new Date()) && (
                      <button
                        style={{
                          ...styles.button,
                          width: 200,
                          backgroundColor: colors.secondary,
                          color: colors.white,
                          padding: 10,
                          borderRadius: 15,
                          margin: 30,
                          cursor: "pointer",
                        }}
                        onClick={toggleAddTurn}
                      >
                        ASSEGNA TURNO
                      </button>
                    )}
                  <div style={{ width: "100%" }}>
                    {onSelecting && (
                      <>
                        <button
                          style={{
                            ...styles.button,
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            cursor: "pointer",
                          }}
                          onClick={toggleAddTurn}
                        >
                          <img
                            alt="Back Button"
                            src={close}
                            style={{
                              height: 15,
                              objectFit: "contain",
                              margin: 20,
                            }}
                          />
                        </button>
                        <div
                          style={{
                            overflowY: "scroll",
                            height: height - 430,
                          }}
                        >
                          <div
                            style={{
                              ...styles.field,
                              marginLeft: 25,
                              marginRight: 25,
                            }}
                          >
                            <p style={styles.label}>Operatore</p>
                            <select
                              name="user"
                              style={styles.menu}
                              value={operator}
                              onChange={(event) =>
                                setOperator(event.target.value)
                              }
                            >
                              {staffList.map((user, key) => {
                                return (
                                  <option key={key} value={user.id}>
                                    {user.username}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div>
                            <div style={styles.row}>
                              <div style={styles.field}>
                                <p style={styles.label}>Orario inizio</p>
                                <input
                                  type="time"
                                  name="startingTime"
                                  value={moment(startingTime).format("HH:mm")}
                                  onChange={(event) => {
                                    let hour = event.target.value.split(":")[0];
                                    let minutes =
                                      event.target.value.split(":")[1];
                                    let temp = new Date(dateSelected);
                                    temp.setHours(hour, minutes, 0, 0);
                                    setStartingTime(temp);
                                  }}
                                  style={{
                                    ...styles.textInput,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    marginRight: 5,
                                    fontFamily: "Arial",
                                    flex: 1,
                                  }}
                                />
                              </div>
                              <div style={styles.field}>
                                <p style={styles.label}>Orario fine</p>
                                <input
                                  type="time"
                                  name="endingTime"
                                  value={moment(endingTime).format("HH:mm")}
                                  onChange={(event) => {
                                    let hour = event.target.value.split(":")[0];
                                    let minutes =
                                      event.target.value.split(":")[1];
                                    let temp = new Date(dateSelected);
                                    temp.setHours(hour, minutes, 0, 0);
                                    setEndingTime(temp);
                                  }}
                                  style={{
                                    ...styles.textInput,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    marginRight: 5,
                                    fontFamily: "Arial",
                                    flex: 1,
                                  }}
                                />
                              </div>
                            </div>
                            {timeError && (
                              <p
                                style={{
                                  color: colors.highlight,
                                  fontSize: 11,
                                  marginLeft: 25,
                                  marginRight: 25,
                                  textAlign: "left",
                                }}
                              >
                                L'orario di fine deve essere successivo
                                all'orario di inizio del turno.
                              </p>
                            )}
                            <div style={styles.field}>
                              <p
                                style={{
                                  ...styles.label,
                                  marginLeft: 25,
                                  marginRight: 25,
                                }}
                              >
                                Mansione
                              </p>
                              <textarea
                                type="text"
                                name="task"
                                autoComplete="on"
                                value={task}
                                onChange={(event) =>
                                  setTask(event.target.value.replace("\n", ""))
                                }
                                placeholder="Descrizione mansione"
                                style={{
                                  ...styles.textInput,
                                  height: 80,
                                  textAlign: "left",
                                  padding: 10,
                                  fontFamily: "Arial",
                                  resize: "none",
                                }}
                              />
                            </div>
                            <div style={styles.field}>
                              <p
                                style={{
                                  ...styles.label,
                                  marginLeft: 25,
                                  marginRight: 25,
                                }}
                              >
                                Ripete ogni{" "}
                              </p>
                              <Week
                                lunedi={lunedi}
                                martedi={martedi}
                                mercoledi={mercoledi}
                                giovedi={giovedi}
                                venerdi={venerdi}
                                sabato={sabato}
                                domenica={domenica}
                                allowEdit={true}
                                setLunedi={(flag) => {
                                  setLunedi(flag);
                                }}
                                setMartedi={(flag) => {
                                  setMartedi(flag);
                                }}
                                setMercoledi={(flag) => {
                                  setMercoledi(flag);
                                }}
                                setGiovedi={(flag) => {
                                  setGiovedi(flag);
                                }}
                                setVenerdi={(flag) => {
                                  setVenerdi(flag);
                                }}
                                setSabato={(flag) => {
                                  setSabato(flag);
                                }}
                                setDomenica={(flag) => {
                                  setDomenica(flag);
                                }}
                                colors={colors}
                              />
                            </div>
                            {isRepeated() && (
                              <div
                                style={{
                                  ...styles.field,
                                  marginLeft: 25,
                                  marginRight: 25,
                                }}
                              >
                                <p style={styles.label}>Fino al</p>
                                <input
                                  type="date"
                                  name="startingDate"
                                  min={moment(startingTime).format(
                                    "yyyy-MM-DD"
                                  )}
                                  value={moment(endingTime).format(
                                    "yyyy-MM-DD"
                                  )}
                                  onChange={(event) => {
                                    let hour = moment(endingTime)
                                      .format("HH:mm")
                                      .split(":")[0];
                                    let minutes = moment(endingTime)
                                      .format("HH:mm")
                                      .split(":")[1];
                                    let temp = new Date(event.target.value);
                                    temp.setHours(hour, minutes, 0, 0);
                                    setEndingTime(temp.toISOString());
                                  }}
                                  placeholder="Data inizio"
                                  style={{
                                    ...styles.textInput,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    marginRight: 5,
                                    fontFamily: "Arial",
                                    flex: 1,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <button
                          style={{
                            ...styles.button,
                            width: 200,
                            backgroundColor: colors.secondary,
                            color: colors.white,
                            padding: 10,
                            borderRadius: 15,
                            margin: 30,
                            cursor: "pointer",
                            opacity: preventTurnCreation() ? 0.5 : 1,
                          }}
                          onClick={() => {
                            setLoaderTurn(true);
                            createTurn();
                          }}
                          disabled={preventTurnCreation()}
                        >
                          ASSEGNA TURNO
                        </button>
                      </>
                    )}{" "}
                    {turnsSelected.length > 0 ? (
                      <div style={styles.field}>
                        <p
                          style={{
                            color: colors.secondary,
                            fontSize: 13,
                            textAlign: "left",
                            marginLeft: 15,
                          }}
                        >
                          Turni
                        </p>
                        {modifyLoader ? (
                          <div
                            style={{
                              display: "flex",
                              marginTop: 20,
                              marginBottom: 20,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <ReactLoading
                              type={"spinningBubbles"}
                              color={colors.darkgray}
                              height={40}
                              width={40}
                            />
                          </div>
                        ) : (
                          <div style={styles.list}>
                            {turnsSelected
                              .sort((a, b) => a.ora_inizio - b.ora_inizio)
                              .map((e, key) => {
                                return (
                                  <TurnInfo
                                    key={key}
                                    turn={e}
                                    idSelected={idSelected}
                                    onModifyTurn={modifyTurn}
                                    modifyLoader={modifyLoader}
                                    onCopyTurn={toggleCopyTurn}
                                    onDeleteTurn={() => toggleDeleteTurn(e)}
                                    staffList={staffList}
                                    colors={colors}
                                  />
                                );
                              })}
                          </div>
                        )}
                      </div>
                    ) : (
                      <p
                        style={{
                          color: colors.secondary,
                          fontSize: 13,
                          marginLeft: 25,
                          marginRight: 25,
                        }}
                      >
                        {filter === -1
                          ? "Nessun operatore in turno."
                          : "L'operatore selezionato non ha turni assegnati per questa data."}
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <DownloadButton getPdf={getPdf} getExcel={getExcel} colors={colors} />
          {showDeleteTurn && (
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RemoveTurn
                turn={turnToDelete}
                closeModal={toggleDeleteTurn}
                saveData={deleteTurn}
                colors={colors}
              />
            </div>
          )}
          {showCopyTurn && (
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CopyTurn
                turn={turnToCopy}
                closeModal={() => {
                  setShowCopyTurn(false);
                }}
                saveData={copyTurn}
                colors={colors}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ShiftSchedule;
