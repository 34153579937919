export const serviceFields = [
  { label: "Data", value: "timestamp", format: "date", sortable: true },
  { label: "Utente", value: "user", subvalue: "username", sortable: true },
  { label: "Ingresso", value: "actualStart", format: "time", sortable: true },
  { label: "Uscita", value: "actualEnd", format: "time", sortable: true },
  { label: "Pausa", value: "break", format: "time", sortable: false },
  {
    label: "Ore di lavoro",
    value: "workingHours",
    format: "time",
    sortable: false,
  },
  { label: "Mansioni", value: "tasks", subvalue: "title", sortable: false },
];

export const serviceFilterFields = [
  {
    identifier: "date",
    label: "Data",
    var: "actualStart",
  },
  {
    identifier: "user",
    label: "Dipendente",
    var: "user",
  },
];
