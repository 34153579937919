import React, { useRef, useEffect, useState, useContext } from "react";
import ReactExport from "react-export-excel";
import { cardsService } from "../../../_services";
import search from "../../../img/search.png";
import download from "../../../img/download.png";
import arrow from "../../../img/arrow_back_dark.png";
import refresh from "../../../img/refresh.png";
import { LanguageContext } from "../../../containers/language";
import Table from "./table";
import BillingForm from "./billingForm";
import moment from "moment";
import ReactLoading from "react-loading";

import Pagination from "react-js-pagination";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const itemsPerPageOptions = [10, 20, 50, 100];

function isEmpty(field) {
  return field === "" || field === null || field === undefined;
}

const InvoiceManager = ({ cardTypes, colors, orgId }) => {
  const [cards, setCards] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [openRecord, setOpenRecord] = useState(null);
  const [tab, setTab] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [tempSearchKey, setTempSearchKey] = useState("");
  const { dictionary } = useContext(LanguageContext);

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const prevActivePage = usePrevious(activePage);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const prevItemsPerPage = usePrevious(itemsPerPage);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [cardsLoaded, setCardsLoaded] = useState(false);

  const [updateLoading, setUpdateLoading] = useState(false);

  const [excelDataNoFattura, setExcelDataNoFattura] = useState([]);
  const [excelDataFattura, setExcelDataFattura] = useState([]);

  const getCardSales = (orgId, start, limit, invoice, searchKey) => {
    setCardsLoaded(false);

    let tempInvoice = false;
    if (invoice === undefined) {
      tempInvoice = false;
    } else {
      if (invoice === 0) {
        tempInvoice = false;
      } else {
        tempInvoice = true;
      }
    }

    cardsService
      .countInvoiceCards(orgId, tempInvoice, searchKey)
      .then((tot) => setTotalItemsCount(tot));

    cardsService
      .getCardSales(orgId, start, limit, tempInvoice, searchKey)
      .then((cards) => {
        const deconstructedCards = JSON.parse(JSON.stringify(cards));
        for (let card of deconstructedCards) {
          let temp = card;
          temp.cap = card.indirizzo.cap;
          temp.indirizzoString =
            card.indirizzo.via +
            ", " +
            card.indirizzo.citta +
            " (" +
            card.indirizzo.provincia +
            ") " +
            card.indirizzo.cap;
          temp.rinnovo = card.rinnovo ? "Rinnovo" : "Vendita";
          temp.tessera =
            card.target_tessere?.nome +
            " - " +
            cardTypes.find(
              (type) => type.id === card.target_tessere.tipologie_tessere
            )?.nome;
        }

        setCards(
          deconstructedCards.sort(function (a, b) {
            if (a.created_at.toLowerCase() < b.created_at.toLowerCase()) {
              return -1;
            }
            if (a.created_at.toLowerCase() > b.created_at.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );
        setCardsLoaded(true);
      });
  };

  const getAllCardSales = (orgId, searchKey) => {
    cardsService
      .getCardSales(orgId, 0, -1, undefined, searchKey)
      .then((cards) => {
        const deconstructedCards = JSON.parse(JSON.stringify(cards));
        var finalColumns = [
          "Tessera",
          "Data di acquisto",
          "Luogo vendita",
          "Metodo di pagamento",
          "Prezzo",
          "Tipologia",
          "Nome",
          "Cognome",
          "Email",
          "Indirizzo",
          "Codice fiscale",
          "Partita IVA",
          "SDI",
        ];

        let finalDataNoFattura = [];
        let finalDataFattura = [];
        for (let card of deconstructedCards) {
          let indirizzoString =
            card.indirizzo.via +
            ", " +
            card.indirizzo.citta +
            " (" +
            card.indirizzo.provincia +
            ") " +
            card.indirizzo.cap;
          const temp = [
            card.target_tessere?.nome +
              " - " +
              cardTypes.find(
                (type) => type.id === card.target_tessere.tipologie_tessere
              )?.nome,
            moment(card.created_at).format("DD/MM/yyyy"),
            camelCase(card.luogo_vendita),
            camelCase(card.metodo_pagamento),
            card.prezzo,
            card.rinnovo ? "Rinnovo" : "Vendita",
            camelCase(card.nome),
            camelCase(card.cognome),
            card.email,
            indirizzoString,
            card.codice_fiscale ? card.codice_fiscale.toString() : "",
            card.partita_iva ? card.partita_iva.toString() : "",
            card.sdi ? card.sdi.toString() : "",
          ];
          if (card.fattura === false) {
            finalDataNoFattura.push(temp);
          } else {
            finalDataFattura.push(temp);
          }
        }
        setExcelDataNoFattura([
          { columns: finalColumns, data: finalDataNoFattura },
        ]);
        setExcelDataFattura([
          { columns: finalColumns, data: finalDataFattura },
        ]);

        setAllCards(
          deconstructedCards.sort(function (a, b) {
            if (a.created_at.toLowerCase() < b.created_at.toLowerCase()) {
              return -1;
            }
            if (a.created_at.toLowerCase() > b.created_at.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );
      });
  };

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const markAs = (id, flag) => {
    setUpdateLoading(true);
    cardsService
      .markInvoiceAs(id, flag, flag === true ? new Date() : null)
      .then(() => {
        setActivePage(1);
        getCardSales(orgId, 0, itemsPerPage, tab);
        setOpenRecord(null);
        setUpdateLoading(false);
      });
  };

  const startingSearch = () => {
    // reset searchKey
    let currentSearchKey = "";
    setSearchKey(currentSearchKey);

    let tempActivePage = 1;
    setActivePage(tempActivePage);

    getCardSales(
      orgId,
      itemsPerPage * tempActivePage - itemsPerPage,
      itemsPerPage,
      tab,
      currentSearchKey
    );
    getAllCardSales(orgId, currentSearchKey);
  };

  useEffect(() => {
    if (
      (prevActivePage !== activePage && prevActivePage !== undefined) ||
      (prevItemsPerPage !== itemsPerPage && prevItemsPerPage !== undefined) ||
      cardTypes.length > 0
    ) {
      getCardSales(
        orgId,
        itemsPerPage * activePage - itemsPerPage,
        itemsPerPage,
        tab,
        searchKey
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orgId,
    activePage,
    prevActivePage,
    itemsPerPage,
    prevItemsPerPage,
    cardTypes,
    tab,
  ]);

  useEffect(() => {
    getAllCardSales(orgId, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, cardTypes, tab]);

  useEffect(() => {
    const handleSubmit = () => {
      setSearchKey(tempSearchKey);

      let tempActivePage = 1;
      setActivePage(tempActivePage);
      getCardSales(
        orgId,
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        tab,
        tempSearchKey
      );
      getAllCardSales(orgId, tempSearchKey);
    };

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, tempSearchKey]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    menu: {
      border: "1px solid lightgray",
      width: 150,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowTop: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: 20,
      paddingTop: 20,
      display: "flex",
      flexDirection: "column",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "50%",
      height: 40,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    downloadButton: {
      border: "none",
      outline: "none",
      backgroundColor: colors.darkgray,
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    goBackButton: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    buttonChoice: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "#e2e2e2",
      padding: 20,
      color: colors.gray,
      border: "1px solid #e2e2e2",
      borderTop: 0,
      fontSize: 12,
      fontWeight: "500",
    },
    filterButton: {
      border: "1px solid #F0F0F0",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.rowTop}>
          <button
            style={{
              ...styles.buttonChoice,
              borderRight: 0,
              backgroundColor: tab === 0 ? "transparent" : "#e2e2e2",
              borderBottom: tab === 0 ? 0 : "1px solid #e2e2e2",
            }}
            onClick={() => {
              setTab(0);
              setActivePage(1);
              setTempSearchKey("");
              setSearchKey("");
            }}
            disabled={!isEmpty(openRecord)}
          >
            NUOVE
          </button>
          <button
            style={{
              ...styles.buttonChoice,
              borderRight: 0,
              backgroundColor: tab === 1 ? "transparent" : "#e2e2e2",
              borderBottom: tab === 1 ? 0 : "1px solid #e2e2e2",
            }}
            onClick={() => {
              setTab(1);
              setActivePage(1);
              setTempSearchKey("");
              setSearchKey("");
            }}
            disabled={!isEmpty(openRecord)}
          >
            EMESSE
          </button>
        </div>
        <div style={styles.rowBottom}>
          {!isEmpty(openRecord) ? (
            <div style={styles.subcontainer}>
              <button
                style={styles.goBackButton}
                onClick={() => setOpenRecord(null)}
              >
                <img src={arrow} style={styles.arrow} alt={"Go back"} />
                <p
                  style={{
                    fontSize: 12,
                    color: colors.darkgray,
                    margin: 0,
                    marginLeft: 5,
                    fontWeight: "600",
                  }}
                >
                  Indietro
                </p>
              </button>
              <BillingForm colors={colors} card={openRecord} />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                {updateLoading ? (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                ) : (
                  <button
                    style={
                      tab === 0
                        ? {
                            ...styles.saveButton,
                            backgroundColor: colors.darkgray,
                          }
                        : {
                            ...styles.saveButton,
                            backgroundColor: colors.white,
                            border: "3px solid #303030",
                          }
                    }
                    onClick={() => markAs(openRecord.id, !openRecord.fattura)}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        color: tab === 0 ? colors.white : "#303030",
                        margin: 0,
                        fontWeight: "600",
                      }}
                    >
                      {tab === 0
                        ? "SEGNA COME EMESSA"
                        : "SEGNA COME DA EMETTERE"}
                    </p>
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div style={styles.subcontainer}>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div style={styles.inputSearch}>
                  <img
                    alt="Search icon"
                    src={search}
                    style={{ width: 30, objectFit: "contain", margin: 10 }}
                  />
                  <input
                    type="text"
                    style={{
                      border: 0,
                      width: "100%",
                      borderRadius: 40,
                      outline: "none",
                    }}
                    value={tempSearchKey}
                    onChange={(event) => {
                      setTempSearchKey(event.target.value);
                    }}
                    placeholder={dictionary.search}
                  ></input>
                  <button
                    style={{
                      ...styles.filterButton,
                    }}
                    onClick={() => {
                      setTempSearchKey("");
                      setSearchKey("");
                      startingSearch(); //come back to the original result search (i.e. the ones at the page opening)
                    }}
                  >
                    <img
                      alt="Refresh icon"
                      src={refresh}
                      style={{ width: 18, objectFit: "contain", margin: 2 }}
                    />
                  </button>
                </div>
                {allCards.length > 0 && (
                  <ExcelFile
                    filename={
                      "Fatture_" + moment(new Date()).format("DD/MM/yy")
                    }
                    element={
                      <button style={styles.downloadButton}>
                        <img
                          alt="Download icon"
                          src={download}
                          style={{ width: 15, objectFit: "contain" }}
                        />
                      </button>
                    }
                  >
                    <ExcelSheet
                      dataSet={excelDataNoFattura}
                      name={"Da emettere"}
                    />
                    {/* {filterColumns().map((col, key) => {
                        return (
                          <ExcelColumn
                            key={key}
                            label={camelCase(getLabel(col))}
                            value={col}
                          />
                        );
                      })}
                    </ExcelSheet> */}
                    <ExcelSheet dataSet={excelDataFattura} name={"Emesse"} />
                    {/* {filterColumns().map((col, key) => {
                        return (
                          <ExcelColumn
                            key={key}
                            label={camelCase(getLabel(col))}
                            value={col}
                          />
                        );
                      })}
                    </ExcelSheet> */}
                  </ExcelFile>
                )}
              </div>
              <div
                id="scroll"
                style={{ overflowY: cardsLoaded ? "scroll" : "hidden" }}
              >
                {cardsLoaded ? (
                  cards.length > 0 ? (
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Table
                        tab={tab}
                        cards={cards}
                        colors={colors}
                        setOpenRecord={setOpenRecord}
                        activePage={activePage}
                        itemsPerPage={itemsPerPage}
                      />
                    </div>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        color: colors.gray,
                        marginTop: 40,
                      }}
                    >
                      Nessuna tessera corrispondente ai criteri della ricerca
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 40,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={colors?.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "90%" }}>
        {cards.length > 0 &&
          totalItemsCount > itemsPerPageOptions[0] &&
          isEmpty(openRecord) && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridAutoRows: "1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <select
                  name="options"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 15,
                    height: 50,
                    width: 70,
                    padding: 10,
                    textAlign: "left",
                    marginRight: 10,
                  }}
                  value={itemsPerPage}
                  onChange={(event) => {
                    setItemsPerPage(parseInt(event.target.value));
                    setActivePage(1);
                  }}
                >
                  {itemsPerPageOptions.map((option, key) => {
                    return (
                      <option key={key} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <p style={{ color: colors?.gray, fontSize: 12 }}>
                  {dictionary.pageElements}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => setActivePage(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default InvoiceManager;
