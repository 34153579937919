import { accessFilterFields } from "./access";
import { serviceFilterFields } from "./service";

export const ACTIONS = ["entrance", "exit", "breakstart", "breakend"];
export const TYPES = ["nfc", "qrcode", "selfcertification"];
export const FORMATS = ["datetime", "time", "date"];
export const ITEMS_PER_PAGE = [10, 20, 50, 100, 200];
export const TABS = [
  {
    id: 0,
    title: "Servizi",
    filters: serviceFilterFields,
  },
  {
    id: 1,
    title: "Accessi",
    filters: accessFilterFields,
  },
];

export function convertMilliseconds(ms) {
  const hours = Math.floor(ms / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));

  return { hours, minutes };
}

export function convertToDecimalHours(timeStr) {
  let hours = 0;
  let minutes = 0;

  const regex = /(\d+)\s*(h|min)/g;
  let match;

  while ((match = regex.exec(timeStr)) !== null) {
    if (match[2] === "h") {
      hours = parseInt(match[1], 10);
    } else if (match[2] === "min") {
      minutes = parseInt(match[1], 10);
    }
  }
  return Number((hours + minutes / 60).toFixed(2));
}

export function formatDate(dateString, format) {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return null;
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  switch (format) {
    case FORMATS[0]:
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    case FORMATS[1]:
      return `${hours}:${minutes}`;
    case FORMATS[2]:
      return `${day}/${month}/${year}`;
    default:
      return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}

export function decode(value, format) {
  if (value === null) return "-";
  if (!!formatDate(value)) {
    return formatDate(value, format);
  } else if (ACTIONS.includes(value)) {
    switch (value) {
      case ACTIONS[0]:
        return "Ingresso";
      case ACTIONS[1]:
        return "Uscita";
      case ACTIONS[2]:
        return "Inizio pausa";
      case ACTIONS[3]:
        return "Fine pausa";
      default:
        return "-";
    }
  } else if (TYPES.includes(value)) {
    switch (value) {
      case TYPES[0]:
        return "NFC";
      case TYPES[1]:
        return "QR code";
      case TYPES[2]:
        return "Auto certificazione";
      default:
        return "-";
    }
  } else if (typeof value === "object") {
    // Currently used only for break
    const total = value
      .map((br) => {
        const start = new Date(br.actualStart);
        const end = new Date(br.actualEnd);
        if (!!start && !!end) return end - start;
        else return 0;
      })
      .reduce((acc, num) => acc + num, 0);

    const differenceInMinutes = total / (1000 * 60); // Millisecondi in ore
    if (!!differenceInMinutes) return Math.round(differenceInMinutes) + " min";
    else return "Nessuna pausa";
  } else {
    return camelCase(value);
  }
}

export function camelCase(str) {
  if (str.length > 0) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  } else {
    return "";
  }
}

export function stringToTitleElement(str) {
  // returns a string with every word first letter in upper case
  //  and no spaces
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return word.toUpperCase();
    })
    .replace(/\s+/g, "");
}
